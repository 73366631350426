div.group {
    div.group--inner {
        display: flex;
        flex-wrap: wrap;
    }

    .tile, .button--tile, .contactcard {
        --el--column-count: 1;
        --gap-count: calc(var(--el--column-count) - 1);

        width: calc(100% / var(--el--column-count) - (var(--gap-count) / var(--el--column-count) * var(--spacing--lg)));
    }

    &.flex--2 {
        .tile, .button--tile, .contactcard {

            @media (--media--md) {
                --el--column-count: 2;
            }
        }
    }

    &.flex--3 {
        .tile, .button--tile, .contactcard {
            @media (--media--md) {
                --el--column-count: 2;
            }
            @media (--media--xl) {
                --el--column-count: 3;
            }
        }
    }

    &.justify--left {
        div.group--inner {
            justify-content: flex-start;
        }
    }

    &.justify--center {
        div.group--inner {
            justify-content: center;

            @media (max-width: 500px) {
                justify-content: flex-start;
            }
        }
    }

    &.gap--md {
        div.group--inner {
            gap: var(--spacing--md);
        }
    }

    &.gap--lg {
        div.group--inner {
            gap: var(--spacing--lg);
        }
    }
}
