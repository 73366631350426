nav.page--subnavigation {
    position: sticky;
    top: 78px;
    z-index: 9998;
    background: var(--color--light);

    ul {
        display: flex;
        grid-column-start: large-inner-start;
        grid-column-end: page-end;
        justify-content: flex-start;
        align-items: baseline;
        width: 100%;
        position: relative;
        padding-top: 3px;
        padding-right: var(--spacing--column);
        padding-bottom: var(--spacing--sm);
        overflow-x: auto;
        scrollbar-color: var(--color--primary) var(--color--light) !important;

        @media (--media--sm) {
            padding-right: calc(var(--spacing--column) * 2);
        }

        @media (--media--lg) {
            grid-column-end: large-inner-end;
            padding-top: var(--spacing--xs);
            padding-right: 0;
        }

        &::-webkit-scrollbar {
            height: 3px !important;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--color--primary) !important;
            border-radius: 10px !important;
        }

        &::-webkit-scrollbar-track {
            background-color: var(--color--light) !important;
        }

        li.page--subnavigation-item {
            list-style-type: none;

            a {
                display: inline-block;
                min-width: 40px;
                font-size: var(--text--base);
                font-weight: 600;
                color: var(--color--font-base);
                background: var(--color--grey);
                padding: calc(var(--spacing--xs) * 0.75);
                margin-right: calc(var(--spacing--xs) * 0.5);
                border-radius: 0 5px 5px 5px;
                text-align: center;
            }

            &:last-child {
                a {
                    margin-right: 0;
                }
            }
        }
    }
}
