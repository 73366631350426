div.card {
    position: relative;
    padding: var(--spacing--column);
    border-radius: var(--radius--xs);

    &::before {
        content: '';
        display: block;
        width: var(--spacing--lg);
        height: 3px;
        background: var(--color--accent);
        border-radius: 1rem;
    }
}
