/* background-colors */
.bg--primary {
	background: var(--color--primary);
}
.bg--secondary {
	background: var(--color--secondary);
}
.bg--accent {
	background: var(--color--accent);
}
.bg--light {
	background: var(--color--light);
}
.bg--grey {
	background: var(--color--grey);
}

/* font-colors */
.fc--font-base {
    color: var(--color--font-base);
}
.fc--accent {
	color: var(--color--accent);
}
.fc--light {
	color: var(--color--light);
}
