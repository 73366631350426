div.search--content {

    div.px-site-document-pagesearch {
        color: var(--color--light);


        form {
            position: relative;
            margin-bottom: var(--spacing--md);

            input {
                width: 100%;
                font-size: var(--text--base);
                line-height: var(--text--base-height);
                font-weight: 700;
                color: var(--color--font-base);
                background: var(--color--grey);
                padding: var(--spacing--xs) var(--spacing--lg);
                border: 2px solid transparent;
                border-radius: var(--radius--xs);
                text-align: center;

                &::placeholder {
                    color: rgba(63, 92, 106, .25);
                }
                &:focus-visible {
                    outline: none;
                }
                &:focus {
                    border: 2px solid var(--color--accent);
                }
                &::-webkit-search-cancel-button {
                    display: none !important;
                }
            }

            button {
                width: calc(var(--spacing--md) * 1.75);
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                font-size: 0;
                color: var(--color--grey);

                &::before {
                    content: url(../Images/navigation--search.svg);
                    display: block;
                    width: var(--spacing--sm);
                    height: var(--spacing--sm);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 1;
                    transform: translate(-50%, -50%);
                }
            }
        }

        div.search--info {
            font-size: var(--text--base);
            color: var(--color--primary) !important;
            text-align: center;
        }

        ul {
            li {
                list-style-type: none;
                padding: var(--spacing--xs);
                margin-bottom: var(--spacing--md);

                a {
                    display: block;
                    position: relative;
                    padding-right: var(--spacing--xl);

                    @media (hover: hover) {
                        &:hover {
                            &::after {
                                right: calc(var(--spacing--xs) * -1);
                            }
                        }
                    }

                    &::after {
                        content: url(../Images/arrow_bold.svg);
                        display: block;
                        width: 2rem;
                        position: absolute;
                        top: var(--headline--lg);
                        right: 0;
                        transform: translateY(-50%);
                        transition: all 0.35s var(--easing--in-out);
                    }
                }
            }
        }
    }
}
