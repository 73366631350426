html {
	font-size: var(--font--base-size);
	scroll-behavior: smooth;
	scroll-padding-top: var(--spacing--lg);
}

body {
	font-family: var(--font--base-family);
	font-size: var(--font--base-size);
	font-weight: 400;
	color: var(--color--font-base);
	background: var(--color--light);
	overflow-x: hidden;
	overflow-y: scroll;
}

.neos-backend {
	.neos-contentcollection {
		& > div {
			width: 100%;
		}
	}
}

::selection {
	text-shadow: none;
	color: var(--color--light);
	background: var(--color--accent);
}

.bg--accent {
    ::selection {
        text-shadow: none;
        color: var(--color--accent);
        background: var(--color--light);
    }
}
