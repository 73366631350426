section.section {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;

    @media(--media--md) {
        background-attachment: fixed;
    }

    video.background--video {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
}
