header.header {
    position: sticky;
    top: 0;
    z-index: 9999;
    background: var(--color--light);

    div.header--inner {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;

        .logo {
            display: inline-block;

            a {
                display: block;
                position: relative;
                padding: var(--spacing--sm) 0;

                svg {
                    display: block;
                    width: 11rem;
                    fill: var(--color--primary);
                }

                span.sr-only {
                    width: 0;
                    height: 0;
                    position: absolute;
                    overflow: hidden;
                }
            }

            &.current {
                a {
                    &::after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 5px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background: var(--color--accent);
                        border-radius: 0 5px 0 0;
                    }
                }
            }
        }
    }

    div.side--bar {
        display: none;

        @media (--media--xl) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            position: absolute;
            top: 10rem;
            right: 0;

            a {
                display: flex;
                align-items: center;
                width: fit-content;
                position: relative;
                left: -60px;
                background: var(--color--accent);
                padding: var(--spacing--xs);
                margin: var(--spacing--xs) 0;
                border-radius: 15px 0 0 15px;
                box-shadow: var(--shadow);
                transform: translateX(100%);
                transition: all 0.35s var(--easing--in-out);

                @media (hover: hover) {
                    &:hover {
                        left: 0;
                        background: var(--color--accent-dark);
                        transform: translateX(0);
                    }
                }

                svg {
                    --svg--size: var(--spacing--md);
                    display: block;
                    width: var(--svg--size);
                    height: var(--svg--size);
                    fill: none;
                    stroke: var(--color--light);
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 3;
                }

                span {
                    display: inline-block;
                    font-size: var(--text--base);
                    font-weight: 500;
                    color: var(--color--light);
                    padding-left: var(--spacing--xs);
                }
            }
        }
    }
}
