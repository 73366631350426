nav.navigation--footer {

    ul.navigation--list {

        li.navigation--listitem {
            display: inline;
            list-style-type: none;
            padding: 0 var(--spacing--sm);
            border-right: 2px solid var(--color--light);

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: none;
            }

            a {
                font-size: var(--text--base);
                line-height: var(--text--base-height);
                font-weight: 600;
                color: var(--color--light);

                @media (hover:hover) {
                    &:hover {
                        color: var(--color--accent);
                    }
                }
            }
        }
    }
}
