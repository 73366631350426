div.paragraph {
    display: block;
    width: 100%;
    hyphens: auto;
    gap: var(--spacing--lg);
    columns: 1;

    @media (--media--md) {
        &.md\:columns-2 {
            columns: 2;
        }
    }
    @media (--media--lg) {
        &.lg\:columns-3 {
            columns: 3;
        }
        &.lg\:columns-4 {
            columns: 4;
        }
    }

    p {
        @media (max-width: 500px) {
            text-align: left !important;
        }
    }

    u {
        text-decoration: none;
        color: var(--color--accent);
    }

    ul {
        li {
            list-style-type: none;
            position: relative;
            padding-left: var(--spacing--xs);

            &::before {
                content: '';
                width: 5px;
                height: 5px;
                position: absolute;
                top: calc(var(--text--base) * 0.6);
                left: 0;
                background: var(--color--font-base);
                border-radius: 1rem;
            }
        }
    }

    a {
        display: inline-block;
        position: relative;
        font-weight: 600;
        color: var(--color--accent);
        padding-left: 2.25rem;
        margin-right: var(--spacing--md);
        transition: color 0.35s var(--easing--in-out);

        @media (hover: hover) {
            &:hover {
                color: var(--color--accent-dark);

                &::before {
                    opacity: 0;
                    transform: translateX(20%) translateY(-40%);
                }

                &::after {
                    transform: translateX(20%) translateY(-40%);
                }
            }
        }

        &::before {
            content: url(../Images/arrow_bold.svg);
            display: inline-block;
            opacity: 1;
            width: 1.5rem;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 1;
            transform: translateX(0) translateY(-40%);
            transition: all 0.35s var(--easing--in-out);
        }

        &::after {
            content: url(../Images/arrow_bold-dark.svg);
            display: inline-block;
            width: 1.5rem;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 0;
            transform: translateX(0) translateY(-40%);
            transition: all 0.35s var(--easing--in-out);
        }

        &[target=_blank] {
            padding-left: 2rem;

            @media (hover: hover) {
                &:hover {
                    &::before {
                        opacity: 0;
                        transform: rotate(-45deg) translateX(50%) translateY(-35%);
                    }
                    &::after {
                        transform: rotate(-45deg) translateX(50%) translateY(-35%);
                    }
                }
            }

            &::before {
                transform: rotate(-45deg) translateX(35%) translateY(-35%);
            }
            &::after {
                transform: rotate(-45deg) translateX(35%) translateY(-35%);
            }
        }
    }

    a[title=glossar] {
        text-decoration: underline;
        color: unset;
        padding-right: 1.05rem;
        padding-left: 0;
        margin-right: 0.25rem;
        text-decoration-style: dotted;

        @media (hover: hover) {
            &:hover {
                color: var(--color--accent-dark);

                &::before {
                    transform: translateX(0) translateY(-45%);
                }

                &::after {
                    transform: translateX(0) translateY(-45%);
                }
            }
        }

        &::before {
            content: url(../Images/recyclingbook--font-base.svg);
            width: 0.9rem;
            left: unset;
            right: 0;
            transform: translateX(0) translateY(-45%);
        }

        &::after {
            content: url(../Images/recyclingbook--accent-dark.svg);
            width: 0.9rem;
            left: unset;
            right: 0;
            transform: translateX(0) translateY(-45%);
        }
    }

    &.fc--accent {
        a[title=glossar] {
            &::before {
                content: url(../Images/recyclingbook--accent.svg);
            }
        }
    }
}
