div.contactcard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: var(--spacing--column);
    margin-top: calc(var(--spacing--lg) * 1.5);
    border-radius: var(--radius--xs);

    &.has--img {
        padding-top: calc(var(--spacing--lg) * 1.25);

        @media (media--md) {
            padding-top: calc(var(--spacing--lg) * 2);
        }

        figure.image--content {
            --img--size: 175px;

            display: block;
            width: var(--img--size);
            height: var(--img--size);
            position: absolute;
            top: calc(var(--spacing--lg) * -1.5);
            left: 50%;
            z-index: 1;
            overflow: hidden;
            border-radius: 100%;
            box-shadow: var(--shadow);
            transform: translateX(-50%);

            @media (media--md) {
                --img--size: 220px;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }

    .text--content {
        text-align: center;

        .text--bold {
            font-weight: 600;
        }

        .headline {
            margin-bottom: calc(var(--spacing--xs) * 0.5);
        }

        .text--phone {
            margin-bottom: calc(var(--spacing--xs) * 0.5);
        }

        p {
            text-align: center !important;
        }
    }

    .link--content {
        display: flex;
        justify-content: center;
    }
}
