footer.footer {
    margin-top: var(--spacing--lg);

    section.newsletter--submit {
        @media (min-width: 500px) {
            text-align: center;
        }

        div.text {
            grid-row-start: 1;
        }

        form.footer--form {
            grid-row-start: 2;
            margin-top: var(--spacing--md);

            div.input--container {
                label.input--label {
                    display: none;
                }

                input.input--box {
                    width: 100%;
                    font-size: var(--text--base);
                    line-height: var(--text--base-height);
                    font-weight: 600;
                    color: var(--color--font-base);
                    padding: var(--spacing--xs);
                    border: none;
                    border-radius: var(--radius--xs);

                    @media (min-width: 500px) {
                        text-align: center;
                    }

                    &::placeholder {
                        color: rgba(63, 92, 106, 0.25);
                    }

                    &:focus-visible {
                        outline-color: var(--color--accent);
                    }
                }
            }

            div.checkbox--container {
                display: flex;
                align-items: center;
                margin-top: var(--spacing--md);

                @media (min-width: 500px) {
                    justify-content: center;
                }

                input.checkbox--box {
                    position: relative;
                    margin-right: 0.5rem;
                    cursor: pointer;

                    &::before {
                        content: "";
                        display: block;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        z-index: 5;
                        background: var(--color--light);
                        border: 1px solid var(--color--primary);
                        border-radius: 3px;
                        transform: translate(-50%, -50%);
                    }

                    &:checked {
                        &::before {
                            background: var(--color--primary);
                        }
                    }
                }

                label.checkbox--label {
                    font-size: var(--text--xs);
                    line-height: var(--text--xs-height);
                    color: var(--color--font-base);

                    a {
                        font-weight: 600;
                        text-decoration: underline;
                        color: var(--color--font-base);
                        transition: color 0.35s var(--easing--in-out);

                        @media (hover: hover) {
                            &:hover {
                                color: var(--color--accent);
                            }
                        }
                    }
                }
            }

            div.button--container {
                margin-top: var(--spacing--md);

                button {
                    font-size: var(--text--base);
                    line-height: var(--text--base-height);
                    font-weight: 600;
                    color: var(--color--light);
                    background: var(--color--accent);
                    padding: 0.25rem var(--spacing--md);
                    border-radius: var(--radius--xl);
                    transition: all 0.35s var(--easing--in-out);

                    @media (hover: hover) {
                        &:hover {
                            background: var(--color--accent-dark);
                        }
                    }
                }
            }

            input.input--hidden {
                display: none;
            }
        }
    }

    section.footer--bottom {
        @media (min-width: 500px) {
            text-align: center;
        }

        div.footer--content--top {
            grid-row-start: 1;

            div.paragraph {
                padding-top: var(--spacing--md);
            }
        }

        div.footer--content--bottom {
            grid-row-start: 2;

            padding-top: var(--spacing--lg);

            div.logo {
                display: inline-block;

                svg {
                    display: block;
                    width: 8rem;
                    fill: var(--color--light);
                }

                span.sr-only {
                    width: 0;
                    height: 0;
                    position: absolute;
                    overflow: hidden;
                }
            }

            small {
                display: block;
                font-size: var(--text--xs);
                line-height: var(--text--xs-height);
                color: var(--color--light);
                padding-top: var(--spacing--sm);
            }
        }
    }
}
