nav.navigation--header {
    height: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--color--light);
    overflow: hidden;
    transition: all 0.5s var(--easing--out);

    @media (--media--xl) {
        display: flex;
        align-items: flex-end;
        height: initial;
        position: unset;
        top: initial;
        left: initial;
        right: initial;
        transition: none;
    }

    &.active {
        height: calc(100vh - 76px);
        overflow-y: auto;
    }
/* Navigation */
    ul.navigation--list {
        grid-row-start: 1;
        padding-top: var(--spacing--lg);

        @media (--media--xl) {
            display: flex;
            align-items: center;
            padding: 0;
        }

        .navigation--listitem {
            list-style-type: none;
            display: block;
            padding: var(--spacing--sm) 0;

            @media (--media--xl) {
                display: inline-block;
                padding: 0 var(--spacing--md);
            }

            .navigation--listitem--link {
                display: inline-block;
                position: relative;
                font-size: var(--headline--xs);
                line-height: var(--headline--xs-height);
                text-transform: uppercase;
                hyphens: auto;
                color: var(--color--font-base);
                padding-left: calc(var(--spacing--md) * 1.25);
                cursor: pointer;
                transition: all 0.35s var(--easing--in-out);

                @media (--media--xl) {
                    font-size: calc(var(--text--base) * 0.9);
                    line-height: calc(var(--text--base-height) * 0.9);
                    padding: var(--spacing--sm) 0;
                    transition: color 0.35s var(--easing--in-out);
                }

                @media (hover: hover) {
                    &:hover {
                        color: var(--color--accent);

                        @media (--media--xl) {
                            &::before, &::after {transform: translateX(0) translateY(0) !important;}
                        }

                        &::before {opacity: 0;}
                        &::before, &::after {transform: translateX(20%) translateY(-40%);}
                    }
                }

                &::before, &::after {
                    content: url(../Images/arrow_primarybold.svg);
                    display: inline-block;
                    opacity: 1;
                    width: 1.5rem;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    z-index: 1;
                    transform: translateX(0) translateY(-40%);
                    transition: all 0.35s var(--easing--in-out);

                    @media (--media--xl) {
                        display: none;
                    }
                }

                &::after {
                    content: url(../Images/arrow_bold.svg);
                    z-index: 0;
                }
            }

            &.current {
                .navigation--listitem--link {
                    font-weight: 600;
                    color: var(--color--accent);

                    @media (--media--xl) {
                        color: var(--color--font-base);
                    }

                    &::before {
                        display: none;
                    }

                    &::after {
                        content: url(../Images/arrow_bold.svg);

                        @media (--media--xl) {
                            content: '';
                            display: block;
                            width: 100%;
                            height: 5px;
                            position: absolute;
                            top: unset;
                            bottom: 0;
                            left: 0;
                            background: var(--color--accent);
                            border-radius: 0 5px 0 0;
                            transform: translateX(0) translateY(0);
                        }
                    }
                }
            }
/* SubNavigation */
            div.subnavigation {
                grid-template-rows: repeat(3, min-content);
                width: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                z-index: 3;
                background: linear-gradient(45deg, var(--color--primary), var(--color--secondary));
                padding-top: var(--spacing--lg);
                padding-bottom: calc(var(--spacing--xl) * 2);
                overflow-y: auto;
                transition: all 0.35s var(--easing--in-out);

                @media (--media--xl) {
                    grid-template-rows: min-content;
                    top: unset;
                    bottom: 100%;
                    left: 0;
                    z-index: -10;
                    padding: var(--spacing--lg) 0;
                }

                &.active {
                    left: 0;

                    @media (--media--xl) {
                        top: 100%;
                        bottom: unset;
                        left: 0;
                    }
                }

                div.subnavigation--back {
                    grid-area: large;
                    grid-row-start: 1;
                    grid-row-end: span 1;
                    font-size: var(--text--base);
                    color: var(--color--accent);
                    margin-bottom: var(--spacing--md);
                    cursor: pointer;

                    @media (--media--xl) {
                        display: none;
                    }
                }

                div.headline {
                    grid-area: large;
                    grid-row-start: 2;
                    grid-row-end: span 1;

                    @media (--media--xl) {
                        display: inline;
                        grid-column-start: large;
                        grid-column-end: span 5;
                        grid-row-start: 1;
                        padding-top: var(--spacing--xs);
                    }
                }

                ul {
                    grid-area: large;
                    grid-row-start: 3;
                    grid-row-end: span 1;

                    @media (--media--xl) {
                        display: inline;
                        grid-column-start: small-inner;
                        grid-column-end: span 9;
                        grid-row-start: 1;
                        padding-left: var(--spacing--lg);
                    }

                    .subnavigation--listitem {
                        list-style-type: none;
                        display: block;
                        padding: var(--spacing--sm) 0;

                        @media (--media--xl) {
                            padding: 0;
                        }

                        .subnavigation--listitem--link {
                            display: inline-block;
                            position: relative;
                            font-size: calc(var(--headline--xs));
                            line-height: calc(var(--headline--xs-height));
                            font-weight: 600;
                            hyphens: auto;
                            color: var(--color--accent);
                            padding-left: calc(var(--spacing--md) * 1.25);
                            cursor: pointer;
                            transition: all 0.35s var(--easing--in-out);

                            @media (--media--xl) {
                                padding-top: var(--spacing--xs);
                                padding-bottom: var(--spacing--xs);
                                transition: color 0.35s var(--easing--in-out);
                            }

                            @media (hover: hover) {
                                &:hover {
                                    color: var(--color--accent-dark);

                                    &::before {opacity: 0;}
                                    &::before, &::after {transform: translateX(20%) translateY(-40%);}
                                }
                            }

                            &::before, &::after {
                                content: url(../Images/arrow_bold.svg);
                                display: inline-block;
                                opacity: 1;
                                width: 1.5rem;
                                position: absolute;
                                top: 50%;
                                left: 0;
                                z-index: 1;
                                transform: translateX(0) translateY(-40%);
                                transition: all 0.35s var(--easing--in-out);
                            }

                            &::after {
                                content: url(../Images/arrow_bold-dark.svg);
                                z-index: 0;
                            }
                        }

                        &.active {
                            .subnavigation--listitem--link {
                                color: var(--color--accent-dark);
                                &::before {opacity: 0;}
                            }
                        }
                    }
                }
            }
        }
    }
/* LanguageNaviation */
    ul.language--list {
        grid-row-start: 2;
        padding-bottom: calc(var(--spacing--xl) * 2);

        @media (--media--xl) {
            grid-row-start: 1;
            padding-left: var(--spacing--md);
        }

        li.language--listitem {
            list-style-type: none;
            display: inline-block;
            padding: var(--spacing--sm) 0;

            &:first-child {
                a {
                    &::after {
                        content: '';
                        width: 1px;
                        height: calc(var(--text--base) * 0.75);
                        position: absolute;
                        top: 50%;
                        right: 0;
                        background: var(--color--font-base);
                        transform: translateY(-50%);
                    }
                }
            }

            &.active {
                font-weight: 600;
            }

            a.language--listitem--link {
                display: inline-block;
                position: relative;
                font-size: var(--headline--xs);
                line-height: var(--headline--xs-height);
                color: var(--color--font-base);
                padding: 0 0.25rem;
                cursor: pointer;


                @media (--media--xl) {
                    font-size: calc(var(--text--base) * 0.9);
                    line-height: calc(var(--text--base-height) * 0.9);
                }
            }
        }
    }
}
