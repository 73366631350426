.tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background: var(--color--grey);
    padding: var(--spacing--column);
    margin-top: var(--spacing--md);
    border-radius: var(--radius--xs);
    transition: background 0.35s var(--easing--in-out);

    &.has--icon {
        --icon--size: 4.5rem;
        padding-top: var(--icon--size);

        @media (--media--md) {
            --icon--size: 5.5rem;
        }

        i.icon {
            width: var(--icon--size);
            height: var(--icon--size);
            position: absolute;
            top: calc(var(--spacing--md) * -1);
            background: var(--color--secondary);
            padding: var(--spacing--xs);
            border-radius: var(--radius--xs);
            box-shadow: var(--shadow);

            svg {
                display: block;
                width: 100%;
                height: 100%;
                fill: none;
                stroke-width: 1;
                stroke: var(--color--light);
                stroke-linecap: round;
                stroke-linejoin: round;
            }
        }
    }
}

a.tile {

    @media (hover: hover) {
        &:hover {
            background: var(--color--grey-dark);

            .link--content {
                color: var(--color--accent-dark);

                svg.arrow {
                    stroke: var(--color--accent-dark);

                    &.intern {
                        transform: translateX(25%) translateY(-50%);
                    }
                    &.extern {
                        transform: rotate(-45deg) translateX(50%) translateY(-50%);
                    }
                }
            }
        }
    }
    .link--content {
        min-height: 25px;
        position: relative;
        font-size: var(--text--base);
        line-height: var(--text--base-height);
        font-weight: 600;
        color: var(--color--accent);
        padding-right: var(--spacing--lg);
        cursor: pointer;
        transition: color 0.35s var(--easing--in-out);

        svg.arrow {
            width: 2rem;
            position: absolute;
            top: 50%;
            right: 0;
            transition: all 0.35s var(--easing--in-out);
            fill: none;
            stroke-width: 3;
            stroke: var(--color--accent);
            stroke-linecap: round;
            stroke-linejoin: round;

            &.intern {
                transform: translateX(0) translateY(-50%);
            }

            &.extern {
                transform: rotate(-45deg) translateX(15%) translateY(-50%);
            }
        }
    }
}
