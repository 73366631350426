div.accordion--item {
    margin: var(--spacing--xs) 0;

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    button {
        display: flex;
        width: 100%;
        position: relative;
        font-size: var(--text--base);
        line-height: var(--text--base-height);
        font-weight: 700;
        color: var(--color--font-base);
        background: var(--color--grey);
        padding: var(--spacing--xs) var(--spacing--sm);
        padding-right: var(--spacing--lg);
        border-radius: var(--radius--xs);
        transition: border-radius 0.35s var(--easing--in-out);
        text-align: left;

        div.accordion--icon {
            width: 1.25rem;
            height: 1.25rem;
            position: absolute;
            top: 50%;
            right: 1.25rem;
            transform: translateY(-50%);

            span {
                display: block;
                width: 100%;
                height: 3px;
                position: absolute;
                top: 50%;
                background: var(--color--accent);
                border-radius: 5px;
                transition: all 0.35s var(--easing--in-out);

                &:first-child {
                    transform: translateY(-50%) rotate(-90deg);
                }

                &:last-child {
                    transform: translateY(-50%);
                }
            }
        }

        &.has--icon {
            align-items: center;
            color: var(--color--accent);
            padding-top: calc(var(--spacing--xs) * 0.737);
            padding-bottom: calc(var(--spacing--xs) * 0.737);

            i.icon {
                --size: 2rem;
                display: inline-block;
                width: var(--size);
                height: var(--size);
                margin-right: var(--spacing--xs);

                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                    fill: none;
                    stroke-width: 1.5;
                    stroke: var(--color--primary);
                    stroke-linecap: round;
                    stroke-linejoin: round;
                }
            }
        }

        &.has--date {
            align-items: baseline;

            small.date {
                font-size: var(--text--xs);
                font-weight: 600;
                color: var(--color--accent);
                margin-right: var(--spacing--xs);
            }
        }
    }

    div.container {

        display: grid;
        grid-template-rows: 0fr;
        background: var(--color--grey);
        margin-top: 0;
        border-radius: var(--radius--xs);
        transition: grid-template-rows 0.5s var(--easing--in-out);
        border-top-right-radius: 0;

        div.container--inner {
            padding: 0 var(--spacing--column);
            overflow: hidden;
            transition: padding 0.5s var(--easing--in-out);
        }
    }

    &.active {
        button {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            div.accordion--icon {
                span:first-child {
                    transform: translateY(-50%) rotate(0deg);
                }
            }
        }

        div.container {
            grid-template-rows: 1fr;

            div.container--inner {
                padding: var(--spacing--column);
            }
        }
    }
}
