div.heroslider--item, div.hero--banner {
    min-height: 20rem;
    overflow: hidden;

    &.medium--height {
        --hero--height: 50vh;
    }
    &.large--height {
        --hero--height: 75vh;
    }

    div.grid--container {
        align-content: end;
        height: 100%;
        min-height: 20rem;
        position: relative;

        @media (--media--sm) {
            min-height: var(--hero--height);
        }

        .background--image, .background--image img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            inset: 0;
        }

        .tile {
            grid-column-start: page-start;
            grid-column-end: page-end;
            grid-row-start: 2;
            z-index: 1;
            background: var(--color--accent);
            border-radius: 0;

            @media (--media--sm) {
                grid-column-start: large-start;
                grid-column-end: center;
                grid-row-start: 1;
                background: rgba(243, 146, 62, 0.9);
                padding-bottom: var(--spacing--column);
                margin-bottom: var(--spacing--lg);
                border-radius: var(--radius--xs);
                align-self: end;
            }

            i.icon {
                background: var(--color--light);

                svg {
                    stroke: var(--color--primary);
                }
            }

            u {
                color: var(--color--light);
            }

            .link--content {
                color: var(--color--light);

                svg.arrow {
                    stroke: var(--color--light);
                }
            }
        }


        div.person--container {
            grid-column-start: medium-start;
            grid-column-end: medium-end;
            grid-row-start: 1;
            height: calc(1 / 3 * 100vh - var(--spacing--md));
            position: relative;
            z-index: 0;
            margin-top: var(--spacing--md);
            margin-bottom: calc(var(--spacing--md) * -1);

            @media (--media--sm) {
                grid-column-start: center-left;
                height: calc(var(--hero--height) - var(--spacing--md));
                min-height: 20rem;
                z-index: 2;
                margin-bottom: 0;
                align-self: end;
            }

            .person--image--1, .person--image--2, .person--image--1 img, .person--image--2 img {
                width: auto;
                height: 100%;
                position: absolute;
                bottom: 0;
                right: 0;
                object-fit: contain;

                @media (--media--sm) {
                    left: 0;
                    right: unset;
                }
            }

            &.image--animation {
                .person--image--1, .person--image--2 {
                    animation-duration: 10s;
                    animation-iteration-count: infinite;
                }

                .person--image--1 {
                    animation-name: image--1;
                }

                .person--image--2 {
                    animation-name: image--2;
                }

                @keyframes image--1 {
                    0% {opacity: 1;}
                    45% {opacity: 1;}
                    50% {opacity: 0;}
                    95% {opacity: 0;}
                    100% {opacity: 1;}
                }
                @keyframes image--2 {
                    0% {opacity: 0;}
                    45% {opacity: 0;}
                    50% {opacity: 1;}
                    95% {opacity: 1;}
                    100% {opacity: 0;}
                }
            }

        }


    }
}
