a.button {
    display: inline-block;
    font-size: var(--text--base);
    line-height: var(--text--base-height);
    font-weight: 600;
    padding: 0.25rem var(--spacing--md);
    border-radius: var(--radius--xl);
    transition: all 0.35s var(--easing--in-out);

    &.bg--accent {
        color: var(--color--light);

        @media (hover: hover) {
            &:hover {
                background: var(--color--accent-dark);
            }
        }
    }
    &.bg--light {
        color: var(--color--font-base);

        @media (hover: hover) {
            &:hover {
                background: var(--color--grey-dark);
            }
        }
    }
    &.bg--grey {
        color: var(--color--font-base);

        @media (hover: hover) {
            &:hover {
                color: var(--color--light);
                background: var(--color--accent);
            }
        }
    }

}
