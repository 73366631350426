div.headline {
	font-family: var(--font--base-family);

	h1, h2, h3, h4, h5, h6 {
		font-weight: 600;
		hyphens: auto;
        word-break: break-word;

        @media (max-width: 500px) {
            span {
                text-align: left !important;
            }
        }

        span:not(.nbsp, .shy) {
            display: block;
        }

        u {
            text-decoration: none;
            color: var(--color--accent);
        }
	}
}
