.grid--container {
    display: grid;
    grid-template-columns:  [page-start]
                                var(--spacing--column)
                                [main-start large-start medium-start small-start hero-tile-start large-inner-start medium-inner-start small-inner-start]
                                    1fr
                                    [center-left]
                                        calc(var(--spacing--column) * 0.5)
                                        [center]
                                        calc(var(--spacing--column) * 0.5)
                                    [center-right]
                                    1fr
                                [small-inner-end medium-inner-end large-inner-end small-end medium-end large-end main-end hero-tile-end]
                                var(--spacing--column)
                            [page-end];

    @media (--media--sm) {
        grid-template-columns:  [page-start]
                                    2.5rem
                                    [main-start large-start medium-start]
                                        var(--spacing--column)
                                        [large-inner-start medium-inner-start]
                                            var(--spacing--column)
                                            [small-start]
                                                var(--spacing--column)
                                                [small-inner-start]
                                                    1fr
                                                    [center-left]
                                                        calc(var(--spacing--column) * 0.5)
                                                        [center]
                                                        calc(var(--spacing--column) * 0.5)
                                                    [center-right]
                                                    1fr
                                                [small-inner-end]
                                                var(--spacing--column)
                                            [small-end]
                                            var(--spacing--column)
                                        [medium-inner-end large-inner-end]
                                        var(--spacing--column)
                                    [medium-end large-end main-end]
                                    2.5rem
                                [page-end];
    }

    @media (--media--md) {
        grid-template-columns:  [page-start]
                                    1.5fr
                                    [main-start large-start]
                                        var(--spacing--column)
                                        [large-inner-start]
                                           1fr
                                            [medium-start]
                                                var(--spacing--column)
                                                [medium-inner-start small-start]
                                                    var(--spacing--column)
                                                    [small-inner-start]
                                                        5fr
                                                        [center-left]
                                                            calc(var(--spacing--column) * 0.5)
                                                            [center]
                                                            calc(var(--spacing--column) * 0.5)
                                                        [center-right]
                                                        5fr
                                                    [small-inner-end]
                                                    var(--spacing--column)
                                                [small-end medium-inner-end]
                                                var(--spacing--column)
                                            [medium-end]
                                            1fr
                                        [large-inner-end]
                                        var(--spacing--column)
                                    [large-end main-end]
                                    1.5fr
                                [page-end];
    }

    @media (--media--lg) {
        grid-template-columns:  [page-start]
                                    var(--spacing--column)
                                    [main-start]
                                        1.75fr
                                        [large-start]
                                            var(--spacing--column)
                                            [large-inner-start]
                                                1fr
                                                [medium-start]
                                                    var(--spacing--column)
                                                    [medium-inner-start]
                                                        1fr
                                                        [small-start]
                                                            var(--spacing--column)
                                                            [small-inner-start]
                                                                4fr
                                                                [center-left]
                                                                    calc(var(--spacing--column) * 0.5)
                                                                    [center]
                                                                    calc(var(--spacing--column) * 0.5)
                                                                [center-right]
                                                                4fr
                                                            [small-inner-end]
                                                            var(--spacing--column)
                                                        [small-end]
                                                        1fr
                                                    [medium-inner-end]
                                                    var(--spacing--column)
                                                [medium-end]
                                                1fr
                                            [large-inner-end]
                                            var(--spacing--column)
                                        [large-end]
                                        1.75fr
                                    [main-end]
                                    var(--spacing--column)
                                [page-end];
    }

    @media (--media--xl) {
        grid-template-columns:  [page-start]
                                    var(--spacing--column)
                                    [main-start]
                                        1.25fr
                                        [large-start]
                                            var(--spacing--column)
                                            [large-inner-start]
                                                1fr
                                                [medium-start]
                                                    var(--spacing--column)
                                                    [medium-inner-start]
                                                        1fr
                                                        [small-start]
                                                            var(--spacing--column)
                                                            [small-inner-start]
                                                                3fr
                                                                [center-left]
                                                                    calc(var(--spacing--column) * 0.5)
                                                                    [center]
                                                                    calc(var(--spacing--column) * 0.5)
                                                                [center-right]
                                                                3fr
                                                            [small-inner-end]
                                                            var(--spacing--column)
                                                        [small-end]
                                                        1fr
                                                    [medium-inner-end]
                                                    var(--spacing--column)
                                                [medium-end]
                                                1fr
                                            [large-inner-end]
                                            var(--spacing--column)
                                        [large-end]
                                        1.25fr
                                    [main-end]
                                    var(--spacing--column)
                                [page-end];
    }

    @media (--media--2xl) {
        grid-template-columns:  [page-start]
                                    var(--spacing--column)
                                    [main-start]
                                        4fr
                                        [large-start]
                                            var(--spacing--column)
                                            [large-inner-start]
                                                1fr
                                                [medium-start]
                                                    var(--spacing--column)
                                                    [medium-inner-start]
                                                        1fr
                                                        [small-start]
                                                            var(--spacing--column)
                                                            [small-inner-start]
                                                                3fr
                                                                [center-left]
                                                                    calc(var(--spacing--column) * 0.5)
                                                                    [center]
                                                                    calc(var(--spacing--column) * 0.5)
                                                                [center-right]
                                                                3fr
                                                            [small-inner-end]
                                                            var(--spacing--column)
                                                        [small-end]
                                                        1fr
                                                    [medium-inner-end]
                                                    var(--spacing--column)
                                                [medium-end]
                                                1fr
                                            [large-inner-end]
                                            var(--spacing--column)
                                        [large-end]
                                        4fr
                                    [main-end]
                                    var(--spacing--column)
                                [page-end];
    }


    .grid-area-\[large\] {
        grid-area: large;
    }
    .grid-area-\[large-inner\] {
        grid-area: large-inner;
    }
    .grid-area-\[medium\] {
        grid-area: medium;
    }
    .grid-area-\[medium-inner\] {
        grid-area: medium-inner;
    }
    .grid-area-\[small\] {
        grid-area: small;
    }
    .grid-area-\[small-inner\] {
        grid-area: small-inner;
    }
}
