.button--tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background: var(--color--grey);
    padding: var(--spacing--column);
    border-radius: var(--radius--xs);

    &.align--left {
        text-align: left !important;
    }

    &.align--center {
        text-align: center !important;

        p {
            text-align: center !important;
        }

        &::before {
            left: 50%;
            transform: translateX(-50%);
        }

        div.button--content {
            align-items: center;
        }
    }

    &.custom--margin--top {
        margin-top: calc(var(--spacing--lg) * 1.5);
    }

    &::before {
        content: '';
        display: block;
        width: var(--spacing--lg);
        height: 3px;
        position: absolute;
        background: var(--color--accent);
        border-radius: 1rem;
    }

    div.button--content {
        display: flex;
        flex-direction: column;

        a.button {
            width: fit-content;
            margin-top: var(--spacing--xs);

            &::first-child {
                margin-top: var(--spacing--md);
            }
        }
    }
}
