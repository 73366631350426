.hero--swiper {
    overflow: hidden;
}


.swiper-slide {
    height: auto !important;
}

.swiper-pagination {
    font-size: 0;
    line-height: 0;
    padding: 0 var(--spacing--column);

    @media (--media--sm) {
        padding: 0;
        text-align: center;
    }
}

.swiper-pagination-bullet {
    display: inline-block;
    width: var(--spacing--md) !important;
    height: 8px;
    background: var(--color--primary);
    margin: 0 calc(var(--spacing--xs) * 0.5);
    border-radius: var(--radius--xs);
    cursor: pointer;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;

    @media (--media--md) {
        width: var(--spacing--lg) !important;
    }

    &:first-child {
        margin-left: 0;
    }
    &:right-child {
        margin-right: 0;
    }
}

.swiper-pagination-bullet-active {
    background: var(--color--accent);
}
